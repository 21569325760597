<!--
 * @Description: 
 * @Version: 1.0
 * @Autor: 杨凯
 * @Date: 2020-10-26 15:02:33
 * @LastEditors: 杨凯
 * @LastEditTime: 2021-12-28 10:47:25
-->
<template>
  <div id="app">
    <transition :name="transitionName">
      <keep-alive :include="[]">
        <router-view :key="key" class="Router"/>
      </keep-alive>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'App',
  data() {
    return {
      transitionName: ""
    }
  }, 
  computed: {
    key() {
      return this.$route.path
    }
  },
  watch: {
    $route(to, from) {
      let isBack = this.$router.isBack // 监听路由变化时的状态为前进还是后退
      if(!isBack) {
        this.transitionName = 'slide-right'
      } else if(this.$router.isBack != null) {
        this.transitionName = 'slide-left'
      } else {
        this.transitionName = 'slide-left'
      }
      this.$router.isBack = false
    }
  }
}
</script>

<style scoped>
.Router {
  position: absolute;
  width: 100%;
  min-height: 100vh;
  transition: all ease 250ms;
}
.slide-left-enter,
.slide-right-leave-active {
    -webkit-transform: translate(100%, 0);
    transform: translate(100%, 0);
}
.slide-left-leave-active,
.slide-right-enter {
    opacity: 0;
    -webkit-transform: translate(-100%, 0);
    transform: translate(-100% 0);
}
</style>