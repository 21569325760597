/*
 * @Description: 路由
 * @Version: 1.0
 * @Autor: 杨凯
 * @Date: 2020-10-26 15:02:33
 * @LastEditors: 杨凯
 * @LastEditTime: 2022-01-12 17:31:08
 */
import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location, onResolve, onReject) {
  if (onResolve || onReject){
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch(err => err)
}

const routes = [
  {
    path: '/',
    name: 'Index',
    redirect: '/home',
  },
  {
    path: '/home',
    name: 'Home',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/create',
    name: 'Create',
    component: () => import(/* webpackChunkName: "about" */ '../views/Create.vue')
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import(/* webpackChunkName: "about" */ '../views/Login.vue')
  },
  {
    path: '/register',
    name: 'Register',
    component: () => import(/* webpackChunkName: "about" */ '../views/Register.vue')
  },
  {
    path: '/feedback',
    name: 'Feedback',
    component: () => import(/* webpackChunkName: "about" */ '../views/Feedback.vue')
  },
  {
    path: '/activity',
    name: 'Activity',
    component: () => import(/* webpackChunkName: "about" */ '../views/Activity.vue')
  },
  {
    path: '/template',
    name: 'Template',
    component: () => import(/* webpackChunkName: "about" */ '../views/Template.vue')
  },
  {
    path: '/service',
    name: 'Service',
    component: () => import(/* webpackChunkName: "about" */ '../views/Service.vue')
  },
  {
    path: '/gptgenerate',
    name: 'GPTGenerate',
    component: () => import(/* webpackChunkName: "about" */ '../views/GPTGenerate.vue')
  },
  {
    path: '/gptmodel',
    name: 'GPTModel',
    component: () => import(/* webpackChunkName: "about" */ '../views/GPTModel.vue')
  },
]

function getAbsolutePath () {
  let path = location.pathname
  return path.substring(0, path.lastIndexOf('/') + 1)
 }

const router = new VueRouter({
  base: getAbsolutePath(),
  routes,
  scrollBehavior(to,from,saveTop)
  { 
    if(saveTop) { 
      return saveTop; 
    } else { 
      return {
        x: 0,
        y: 0
      } 
    } 
  },
})

export default router
