/*
 * @Description:
 * @Version: 1.0
 * @Autor: 杨凯
 * @Date: 2020-10-26 15:02:33
 * @LastEditors: 杨凯
 * @LastEditTime: 2022-01-26 16:59:21
 */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import 'vant/lib/index.css'
import Vant from 'vant'
import 'vant/lib/index.css'
import axios from 'axios'
import '@/assets/iconfont/iconfont.css'
import { Toast } from 'vant'
// main.js
import moment from 'moment'
import VueLetterAvatar from 'vue-letter-avatar';

Vue.use(VueLetterAvatar);
Vue.use(Toast)
Vue.use(Vant)

Vue.config.productionTip = false

Vue.prototype.$http = axios

Vue.prototype.moment = moment

Vue.filter('formtime', (originVal) => {
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')
  const hh = (dt.getHours() + '').padStart(2, '0')
  const mm = (dt.getMinutes() + '').padStart(2, '0')
  const ss = (dt.getSeconds() + '').padStart(2, '0')

  return originVal ? `${y}-${m}-${d} ${hh}:${mm}:${ss}` : '---'
})

Vue.filter('formdate', (originVal) => {
  const dt = new Date(originVal)
  const y = dt.getFullYear()
  const m = (dt.getMonth() + 1 + '').padStart(2, '0')
  const d = (dt.getDate() + '').padStart(2, '0')

  return originVal ? `${y}-${m}-${d}` : '---'
})

window.onload = function () {
  window.addEventListener('popstate', function (e) {
    router.isBack = true
  }, false)
}

router.beforeEach((to, from, next) => {
  next()
  return
  if (!window.PosObj) {
    location.href = "http://chatgpt.insfair.cn/#/error";
  } else {
    next();
  }
  return
})

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app')
